import React from "react";
import Footer from "../../components/Footer/Footer";
import PagesTopSection from "../../components/PagesTopSection/PagesTopSection";
import "./about.css";
import heartImage from "../../assets/Images/Heart.png";
import starImage from "../../assets/Images/Star.png";
import chatImage from "../../assets/Images/Chat.png";
import sendImage from "../../assets/Images/Send.png";
import Image1 from "../../assets/Images/WhatsApp_Image_2024-06-18_at_11.04.48_AM-removebg-preview 1.png";
import Image2 from "../../assets/Images/WhatsApp_Image_2024-06-18_at_11.04.48_AM-removebg-preview 1.png";
import Image3 from "../../assets/Images/WhatsApp_Image_2024-06-18_at_11.04.48_AM-removebg-preview 1.png";
import Image4 from "../../assets/Images/WhatsApp_Image_2024-06-18_at_11.04.48_AM-removebg-preview 1.png";
import IndiaMapImage from "../../assets/Images/Group 14713 (1).png";
const AboutUs = ({
  value,
  setValue,
  showProductMenu,
  setShowProductMenu,
  topSection = {
    title: null,
    logo: true,
    description: "Financial Services",
    fontWeight: '600'
  },

  
}) => {
  const Data = [
    {
      image: heartImage,
      title: "Culture",
      description:
        "V Care priorities collaborative, ethical work environment and employee growth.",
    },
    {
      image: starImage,
      title: "Integrity",
      description:
        "V Care upholds unwavering integrity in all financial advice and operations.",
    },
    {
      image: chatImage,
      title: "Support",
      description: "V Care places client needs at the core of every decision.",
    },
    {
      image: sendImage,
      title: "Automation",
      description:
        "V Care leverages cutting-edge automation to enhance efficiency .",
    },
  ];

  const Data2 = [
    {
      image: Image1,
      description:
        "Purchasing Finance is the working capital financing solution provided to SMEs. Due to a lack of cash flow, SMEs are forced to buy raw materials ",
    },
    {
      image: Image2,
      description:
        "Purchasing Finance is the working capital financing solution provided to SMEs. Due to a lack of cash flow, SMEs are forced to buy raw materials ",
    },
    {
      image: Image3,
      description:
        "Purchasing Finance is the working capital financing solution provided to SMEs. Due to a lack of cash flow, SMEs are forced to buy raw materials ",
    },
    {
      image: Image4,
      description:
        "Purchasing Finance is the working capital financing solution provided to SMEs. Due to a lack of cash flow, SMEs are forced to buy raw materials ",
    },
  ];

  return (
    <div className="aboutUsContainer">
      <div className="containerContent">
        <PagesTopSection
          value={value}
          setValue={setValue}
          showProductMenu={showProductMenu}
          setShowProductMenu={setShowProductMenu}
          topSection={topSection}
        />

        <div className="container-content-header">
          <div className="container-content-header-content">
            <h1 className="container-content-header-h1">Enabling The Smart</h1>
            <h2 className="container-content-header-h2">Financial Advice</h2>
          </div>
        </div>

        <div className="aboutUs-section-3">
          <div className="whoWeAre-row">
            <h1 className="title">Who We Are</h1>
            <p className="description">
              Our mission at V Care Financial Services is to empower businesses
              by providing easy access to strategic financial solutions. We
              prioritize integrity, transparency, and customer-centricity to
              ensure your success.Our highly experienced team of finance
              professionals works relentlessly to offer customised financial
              solutions that fit your unique needs. Stay rest assured, as we are
              committed to staying updated on industry trends and regulations.
              Let us be your partner for life, offering ongoing support and
              guidance every step of the way. Together, we’ll unlock your
              business’ true potential. Started in 2008 , V Care has witnessed
              tremendous growth among its clientele during its journey . We are
              firm believers of Value addition over valuation .
            </p>
          </div>

          <div className="ourKeyValues-row">
            <div className="leftSideContent">
              <h1 className="title">Our Key Values</h1>
              <p className="description">
                At V Care, We firmly believe that upholding integrity, ethical
                practices, and client-centric service ultimately leads to
                sustainable success and long-term client trust.
              </p>
            </div>
            <div className="rightSideContent">
              {Data.map((data, index) => (
                <div key={index} className="card">
                  <img src={data.image} alt="" />
                  <p className="title">{data.title}</p>
                  <p className="description">{data.description}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="ourTeam-row">
            <h1 className="ourTeam-row-header">Our Team</h1>

            <div className="teamCard">
              {Data2.map((data, index) => (
                <div key={index} className="card">
                  <div className="image">
                    <img src={data.image} alt="" />
                  </div>
                  <p className="description">{data.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="our-presence">
          <div className="our-presence-header-content">
            <h1 className="our-presence-header">Our Presence</h1>
            <p className="our-presence-description">
              Purchasing Finance is the working capital financing solution
              provided to SMEs. Due to a lack of cash flow, SMEs are forced to
              buy raw materials at higher prices and tend to lose opportunities
              from reputable customers. In such situations, SMEs can consider
              purchase finance solution to bridge the working capital gap.
            </p>
          </div>
          <div className="our-presence-info">
            <div className="our-presence-stats">
              <div className="our-presence-stats-item">
                <h2 className="our-presence-stats-item-number">18+</h2>
                <p className="our-presence-stats-item-label">Years</p>
              </div>
              <div className="our-presence-stats-item">
                <h2 className="our-presence-stats-item-number">20+</h2>
                <p className="our-presence-stats-item-label">Cities</p>
              </div>
              <div className="our-presence-stats-item">
                <h2 className="our-presence-stats-item-number">100+</h2>
                <p className="our-presence-stats-item-label">Partners</p>
              </div>
            </div>
            <div className="our-presence-image">
              <img src={IndiaMapImage} alt="" />
            </div>
            <div className="our-presence-stats">
              <div className="our-presence-stats-item">
                <h2 className="our-presence-stats-item-number">10+</h2>
                <p className="our-presence-stats-item-label">Products</p>
              </div>
              <div className="our-presence-stats-item">
                <h2 className="our-presence-stats-item-number">500+</h2>
                <p className="our-presence-stats-item-label">Clientele</p>
              </div>
              <div className="our-presence-stats-item">
                <h2 className="our-presence-stats-item-number">1000+</h2>
                <p className="our-presence-stats-item-label">Crore Disbursed</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer setValue={setValue} />
    </div>
  );
};

export default AboutUs;
