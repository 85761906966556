import React from "react";
import Navbar from "../Navbar/Navbar";
import buildingImage from "../../assets/ContactUs Page Image/Group 14712.png";
import "./pagesTopSection.css";

const PagesTopSection = ({
  value,
  setValue,
  showProductMenu,
  setShowProductMenu,
  topSection,
}) => {
  return (
    <div className="topSection">
      <div className="contactUsNavbar">
        <Navbar
          setValue={setValue}
          value={value}
          showProductMenu={showProductMenu}
          setShowProductMenu={setShowProductMenu}
        />
      </div>
      <div className="topSectionHeading">
        {topSection.title && (
          <h1 className="topSectionHeading-h1">{topSection.title}</h1>
        )}
        {topSection.logo && (
          <h1 className="logo">
            V<span className="logoLastWord">care</span>
          </h1>
        )}
        <p
          className={`topSectionHeading-p ${topSection.description.replace(
            " ",
            ""
          )}`}
          style={{ fontWeight: topSection.fontWeight }}
        >
          {topSection.description}
        </p>
      </div>
      <div className="topSectionImage">
        <img src={buildingImage} alt="" />
      </div>
    </div>
  );
};

export default PagesTopSection;
